"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MFTOutboxItem = exports.MFTInboxItem = exports.GroupsWithAccess = exports.MFTMessage = exports.OutboxRoot = exports.InboxRoot = exports.protobufPackage = void 0;
/* eslint-disable */
const item_1 = require("./item");
const timestamp_1 = require("./google/protobuf/timestamp");
const permission_1 = require("./permission");
const groups_1 = require("./groups");
const settings_1 = require("./settings");
exports.protobufPackage = 'de.netfiles.pb';
function createBaseInboxRoot() {
    return {
        id: '',
        name: '',
        username: '',
        title: '',
        numChildren: 0,
        subDocs: undefined,
        effectivePermission: undefined,
        description: '',
        itemType: 0,
    };
}
exports.InboxRoot = {
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : '',
            name: isSet(object.name) ? String(object.name) : '',
            username: isSet(object.username) ? String(object.username) : '',
            title: isSet(object.title) ? String(object.title) : '',
            numChildren: isSet(object.numChildren) ? Number(object.numChildren) : 0,
            subDocs: isSet(object.subDocs) ? Number(object.subDocs) : undefined,
            effectivePermission: isSet(object.effectivePermission)
                ? permission_1.EffectivePermission.fromJSON(object.effectivePermission)
                : undefined,
            description: isSet(object.description) ? String(object.description) : '',
            itemType: isSet(object.itemType) ? (0, item_1.itemTypeFromJSON)(object.itemType) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        message.username !== undefined && (obj.username = message.username);
        message.title !== undefined && (obj.title = message.title);
        message.numChildren !== undefined &&
            (obj.numChildren = Math.round(message.numChildren));
        message.subDocs !== undefined &&
            (obj.subDocs = Math.round(message.subDocs));
        message.effectivePermission !== undefined &&
            (obj.effectivePermission = message.effectivePermission
                ? permission_1.EffectivePermission.toJSON(message.effectivePermission)
                : undefined);
        message.description !== undefined &&
            (obj.description = message.description);
        message.itemType !== undefined &&
            (obj.itemType = (0, item_1.itemTypeToJSON)(message.itemType));
        return obj;
    },
};
function createBaseOutboxRoot() {
    return {
        id: '',
        name: '',
        username: '',
        title: '',
        numChildren: 0,
        subDocs: undefined,
        effectivePermission: undefined,
        description: '',
        itemType: 0,
    };
}
exports.OutboxRoot = {
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : '',
            name: isSet(object.name) ? String(object.name) : '',
            username: isSet(object.username) ? String(object.username) : '',
            title: isSet(object.title) ? String(object.title) : '',
            numChildren: isSet(object.numChildren) ? Number(object.numChildren) : 0,
            subDocs: isSet(object.subDocs) ? Number(object.subDocs) : undefined,
            effectivePermission: isSet(object.effectivePermission)
                ? permission_1.EffectivePermission.fromJSON(object.effectivePermission)
                : undefined,
            description: isSet(object.description) ? String(object.description) : '',
            itemType: isSet(object.itemType) ? (0, item_1.itemTypeFromJSON)(object.itemType) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        message.username !== undefined && (obj.username = message.username);
        message.title !== undefined && (obj.title = message.title);
        message.numChildren !== undefined &&
            (obj.numChildren = Math.round(message.numChildren));
        message.subDocs !== undefined &&
            (obj.subDocs = Math.round(message.subDocs));
        message.effectivePermission !== undefined &&
            (obj.effectivePermission = message.effectivePermission
                ? permission_1.EffectivePermission.toJSON(message.effectivePermission)
                : undefined);
        message.description !== undefined &&
            (obj.description = message.description);
        message.itemType !== undefined &&
            (obj.itemType = (0, item_1.itemTypeToJSON)(message.itemType));
        return obj;
    },
};
function createBaseMFTMessage() {
    return { name: '', message: '', messagedate: undefined };
}
exports.MFTMessage = {
    fromJSON(object) {
        return {
            name: isSet(object.name) ? String(object.name) : '',
            message: isSet(object.message) ? String(object.message) : '',
            messagedate: isSet(object.messagedate)
                ? fromJsonTimestamp(object.messagedate)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.name !== undefined && (obj.name = message.name);
        message.message !== undefined && (obj.message = message.message);
        message.messagedate !== undefined &&
            (obj.messagedate = message.messagedate.toISOString());
        return obj;
    },
};
function createBaseGroupsWithAccess() {
    return { groups: [] };
}
exports.GroupsWithAccess = {
    fromJSON(object) {
        return {
            groups: Array.isArray(object?.groups)
                ? object.groups.map((e) => groups_1.Group.fromJSON(e))
                : [],
        };
    },
    toJSON(message) {
        const obj = {};
        if (message.groups) {
            obj.groups = message.groups.map(e => (e ? groups_1.Group.toJSON(e) : undefined));
        }
        else {
            obj.groups = [];
        }
        return obj;
    },
};
function createBaseMFTInboxItem() {
    return {
        id: '',
        name: '',
        username: '',
        creationDate: undefined,
        modificationDate: undefined,
        realName: undefined,
        title: '',
        subDocs: undefined,
        effectivePermission: undefined,
        subject: '',
        messages: [],
        receiverEmail: '',
        receiverLanguage: undefined,
        password: undefined,
        passwordRequired: undefined,
        emailVerificationRequired: undefined,
        restrictAccessFrom: undefined,
        restrictAccessTo: undefined,
        link: undefined,
        createLinkOnly: undefined,
        groupsWithAccess: undefined,
        isLocked: false,
        activityLogId: '',
        failedLogins: undefined,
        itemType: 0,
    };
}
exports.MFTInboxItem = {
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : '',
            name: isSet(object.name) ? String(object.name) : '',
            username: isSet(object.username) ? String(object.username) : '',
            creationDate: isSet(object.creationDate)
                ? fromJsonTimestamp(object.creationDate)
                : undefined,
            modificationDate: isSet(object.modificationDate)
                ? fromJsonTimestamp(object.modificationDate)
                : undefined,
            realName: isSet(object.realName) ? String(object.realName) : undefined,
            title: isSet(object.title) ? String(object.title) : '',
            subDocs: isSet(object.subDocs) ? Number(object.subDocs) : undefined,
            effectivePermission: isSet(object.effectivePermission)
                ? permission_1.EffectivePermission.fromJSON(object.effectivePermission)
                : undefined,
            subject: isSet(object.subject) ? String(object.subject) : '',
            messages: Array.isArray(object?.messages)
                ? object.messages.map((e) => exports.MFTMessage.fromJSON(e))
                : [],
            receiverEmail: isSet(object.receiverEmail)
                ? String(object.receiverEmail)
                : '',
            receiverLanguage: isSet(object.receiverLanguage)
                ? (0, settings_1.languageFromJSON)(object.receiverLanguage)
                : undefined,
            password: isSet(object.password) ? String(object.password) : undefined,
            passwordRequired: isSet(object.passwordRequired)
                ? Boolean(object.passwordRequired)
                : undefined,
            emailVerificationRequired: isSet(object.emailVerificationRequired)
                ? Boolean(object.emailVerificationRequired)
                : undefined,
            restrictAccessFrom: isSet(object.restrictAccessFrom)
                ? fromJsonTimestamp(object.restrictAccessFrom)
                : undefined,
            restrictAccessTo: isSet(object.restrictAccessTo)
                ? fromJsonTimestamp(object.restrictAccessTo)
                : undefined,
            link: isSet(object.link) ? String(object.link) : undefined,
            createLinkOnly: isSet(object.createLinkOnly)
                ? Boolean(object.createLinkOnly)
                : undefined,
            groupsWithAccess: isSet(object.groupsWithAccess)
                ? exports.GroupsWithAccess.fromJSON(object.groupsWithAccess)
                : undefined,
            isLocked: isSet(object.isLocked) ? Boolean(object.isLocked) : false,
            activityLogId: isSet(object.activityLogId)
                ? String(object.activityLogId)
                : '',
            failedLogins: isSet(object.failedLogins)
                ? Number(object.failedLogins)
                : undefined,
            itemType: isSet(object.itemType) ? (0, item_1.itemTypeFromJSON)(object.itemType) : 0,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        message.username !== undefined && (obj.username = message.username);
        message.creationDate !== undefined &&
            (obj.creationDate = message.creationDate.toISOString());
        message.modificationDate !== undefined &&
            (obj.modificationDate = message.modificationDate.toISOString());
        message.realName !== undefined && (obj.realName = message.realName);
        message.title !== undefined && (obj.title = message.title);
        message.subDocs !== undefined &&
            (obj.subDocs = Math.round(message.subDocs));
        message.effectivePermission !== undefined &&
            (obj.effectivePermission = message.effectivePermission
                ? permission_1.EffectivePermission.toJSON(message.effectivePermission)
                : undefined);
        message.subject !== undefined && (obj.subject = message.subject);
        if (message.messages) {
            obj.messages = message.messages.map(e => e ? exports.MFTMessage.toJSON(e) : undefined);
        }
        else {
            obj.messages = [];
        }
        message.receiverEmail !== undefined &&
            (obj.receiverEmail = message.receiverEmail);
        message.receiverLanguage !== undefined &&
            (obj.receiverLanguage =
                message.receiverLanguage !== undefined
                    ? (0, settings_1.languageToJSON)(message.receiverLanguage)
                    : undefined);
        message.password !== undefined && (obj.password = message.password);
        message.passwordRequired !== undefined &&
            (obj.passwordRequired = message.passwordRequired);
        message.emailVerificationRequired !== undefined &&
            (obj.emailVerificationRequired = message.emailVerificationRequired);
        message.restrictAccessFrom !== undefined &&
            (obj.restrictAccessFrom = message.restrictAccessFrom.toISOString());
        message.restrictAccessTo !== undefined &&
            (obj.restrictAccessTo = message.restrictAccessTo.toISOString());
        message.link !== undefined && (obj.link = message.link);
        message.createLinkOnly !== undefined &&
            (obj.createLinkOnly = message.createLinkOnly);
        message.groupsWithAccess !== undefined &&
            (obj.groupsWithAccess = message.groupsWithAccess
                ? exports.GroupsWithAccess.toJSON(message.groupsWithAccess)
                : undefined);
        message.isLocked !== undefined && (obj.isLocked = message.isLocked);
        message.activityLogId !== undefined &&
            (obj.activityLogId = message.activityLogId);
        message.failedLogins !== undefined &&
            (obj.failedLogins = Math.round(message.failedLogins));
        message.itemType !== undefined &&
            (obj.itemType = (0, item_1.itemTypeToJSON)(message.itemType));
        return obj;
    },
};
function createBaseMFTOutboxItem() {
    return {
        id: '',
        name: '',
        username: '',
        creationDate: undefined,
        modificationDate: undefined,
        realName: undefined,
        title: '',
        subDocs: undefined,
        effectivePermission: undefined,
        subject: '',
        message: undefined,
        receiverEmails: [],
        receiverLanguage: undefined,
        password: undefined,
        passwordRequired: undefined,
        emailVerificationRequired: undefined,
        restrictAccessTo: undefined,
        link: undefined,
        createLinkOnly: undefined,
        linkedItems: [],
        notifyAfterDownload: undefined,
        emailBccToMe: undefined,
        emailTextOnly: undefined,
        useSecurePdf: undefined,
        useSecurePdfWatermarks: undefined,
        groupsWithAccess: undefined,
        isLocked: false,
        itemType: 0,
        activityLogId: '',
        mftDeleted: undefined,
        failedLogins: undefined,
    };
}
exports.MFTOutboxItem = {
    fromJSON(object) {
        return {
            id: isSet(object.id) ? String(object.id) : '',
            name: isSet(object.name) ? String(object.name) : '',
            username: isSet(object.username) ? String(object.username) : '',
            creationDate: isSet(object.creationDate)
                ? fromJsonTimestamp(object.creationDate)
                : undefined,
            modificationDate: isSet(object.modificationDate)
                ? fromJsonTimestamp(object.modificationDate)
                : undefined,
            realName: isSet(object.realName) ? String(object.realName) : undefined,
            title: isSet(object.title) ? String(object.title) : '',
            subDocs: isSet(object.subDocs) ? Number(object.subDocs) : undefined,
            effectivePermission: isSet(object.effectivePermission)
                ? permission_1.EffectivePermission.fromJSON(object.effectivePermission)
                : undefined,
            subject: isSet(object.subject) ? String(object.subject) : '',
            message: isSet(object.message) ? String(object.message) : undefined,
            receiverEmails: Array.isArray(object?.receiverEmails)
                ? object.receiverEmails.map((e) => String(e))
                : [],
            receiverLanguage: isSet(object.receiverLanguage)
                ? (0, settings_1.languageFromJSON)(object.receiverLanguage)
                : undefined,
            password: isSet(object.password) ? String(object.password) : undefined,
            passwordRequired: isSet(object.passwordRequired)
                ? Boolean(object.passwordRequired)
                : undefined,
            emailVerificationRequired: isSet(object.emailVerificationRequired)
                ? Boolean(object.emailVerificationRequired)
                : undefined,
            restrictAccessTo: isSet(object.restrictAccessTo)
                ? fromJsonTimestamp(object.restrictAccessTo)
                : undefined,
            link: isSet(object.link) ? String(object.link) : undefined,
            createLinkOnly: isSet(object.createLinkOnly)
                ? Boolean(object.createLinkOnly)
                : undefined,
            linkedItems: Array.isArray(object?.linkedItems)
                ? object.linkedItems.map((e) => String(e))
                : [],
            notifyAfterDownload: isSet(object.notifyAfterDownload)
                ? Boolean(object.notifyAfterDownload)
                : undefined,
            emailBccToMe: isSet(object.emailBccToMe)
                ? Boolean(object.emailBccToMe)
                : undefined,
            emailTextOnly: isSet(object.emailTextOnly)
                ? Boolean(object.emailTextOnly)
                : undefined,
            useSecurePdf: isSet(object.useSecurePdf)
                ? Boolean(object.useSecurePdf)
                : undefined,
            useSecurePdfWatermarks: isSet(object.useSecurePdfWatermarks)
                ? Boolean(object.useSecurePdfWatermarks)
                : undefined,
            groupsWithAccess: isSet(object.groupsWithAccess)
                ? exports.GroupsWithAccess.fromJSON(object.groupsWithAccess)
                : undefined,
            isLocked: isSet(object.isLocked) ? Boolean(object.isLocked) : false,
            itemType: isSet(object.itemType) ? (0, item_1.itemTypeFromJSON)(object.itemType) : 0,
            activityLogId: isSet(object.activityLogId)
                ? String(object.activityLogId)
                : '',
            mftDeleted: isSet(object.mftDeleted)
                ? fromJsonTimestamp(object.mftDeleted)
                : undefined,
            failedLogins: isSet(object.failedLogins)
                ? Number(object.failedLogins)
                : undefined,
        };
    },
    toJSON(message) {
        const obj = {};
        message.id !== undefined && (obj.id = message.id);
        message.name !== undefined && (obj.name = message.name);
        message.username !== undefined && (obj.username = message.username);
        message.creationDate !== undefined &&
            (obj.creationDate = message.creationDate.toISOString());
        message.modificationDate !== undefined &&
            (obj.modificationDate = message.modificationDate.toISOString());
        message.realName !== undefined && (obj.realName = message.realName);
        message.title !== undefined && (obj.title = message.title);
        message.subDocs !== undefined &&
            (obj.subDocs = Math.round(message.subDocs));
        message.effectivePermission !== undefined &&
            (obj.effectivePermission = message.effectivePermission
                ? permission_1.EffectivePermission.toJSON(message.effectivePermission)
                : undefined);
        message.subject !== undefined && (obj.subject = message.subject);
        message.message !== undefined && (obj.message = message.message);
        if (message.receiverEmails) {
            obj.receiverEmails = message.receiverEmails.map(e => e);
        }
        else {
            obj.receiverEmails = [];
        }
        message.receiverLanguage !== undefined &&
            (obj.receiverLanguage =
                message.receiverLanguage !== undefined
                    ? (0, settings_1.languageToJSON)(message.receiverLanguage)
                    : undefined);
        message.password !== undefined && (obj.password = message.password);
        message.passwordRequired !== undefined &&
            (obj.passwordRequired = message.passwordRequired);
        message.emailVerificationRequired !== undefined &&
            (obj.emailVerificationRequired = message.emailVerificationRequired);
        message.restrictAccessTo !== undefined &&
            (obj.restrictAccessTo = message.restrictAccessTo.toISOString());
        message.link !== undefined && (obj.link = message.link);
        message.createLinkOnly !== undefined &&
            (obj.createLinkOnly = message.createLinkOnly);
        if (message.linkedItems) {
            obj.linkedItems = message.linkedItems.map(e => e);
        }
        else {
            obj.linkedItems = [];
        }
        message.notifyAfterDownload !== undefined &&
            (obj.notifyAfterDownload = message.notifyAfterDownload);
        message.emailBccToMe !== undefined &&
            (obj.emailBccToMe = message.emailBccToMe);
        message.emailTextOnly !== undefined &&
            (obj.emailTextOnly = message.emailTextOnly);
        message.useSecurePdf !== undefined &&
            (obj.useSecurePdf = message.useSecurePdf);
        message.useSecurePdfWatermarks !== undefined &&
            (obj.useSecurePdfWatermarks = message.useSecurePdfWatermarks);
        message.groupsWithAccess !== undefined &&
            (obj.groupsWithAccess = message.groupsWithAccess
                ? exports.GroupsWithAccess.toJSON(message.groupsWithAccess)
                : undefined);
        message.isLocked !== undefined && (obj.isLocked = message.isLocked);
        message.itemType !== undefined &&
            (obj.itemType = (0, item_1.itemTypeToJSON)(message.itemType));
        message.activityLogId !== undefined &&
            (obj.activityLogId = message.activityLogId);
        message.mftDeleted !== undefined &&
            (obj.mftDeleted = message.mftDeleted.toISOString());
        message.failedLogins !== undefined &&
            (obj.failedLogins = Math.round(message.failedLogins));
        return obj;
    },
};
function fromTimestamp(t) {
    let millis = t.seconds * 1000;
    millis += t.nanos / 1000000;
    return new Date(millis);
}
function fromJsonTimestamp(o) {
    if (o instanceof Date) {
        return o;
    }
    else if (typeof o === 'string') {
        return new Date(o);
    }
    else {
        return fromTimestamp(timestamp_1.Timestamp.fromJSON(o));
    }
}
function isSet(value) {
    return value !== null && value !== undefined;
}
