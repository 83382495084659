export const gitInfo: Record<string, string> = {
  'dirty': 'true',
  'hash': 'g1efd1f7',
  'distance': '229',
  'tag': 'v1.0.0stg',
  'semver': 'null',
  'suffix': '229-g1efd1f7-dirty',
  'semverString': '',
};

export const appVersion: string = '1.0.1+9';
