/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { LogFormat, MainLogger } from './MainLogger';
import { Request } from 'express';
import { appVersion, gitInfo } from '../../gitinfo';

type GeoData = {
  continent?: string;
  country?: string;
  ip?: string;
};

type Annotation = {
  data?: string;
  itemId?: string;
  type?: string;
};

type Params = {
  action?: string;
  activity?: string;
  activityLogType?: string;
  additionalRights?: string;
  annotations?: Annotation;
  apiKey?: string;
  apiServerId?: string;
  apiServerSecret?: string;
  apiUsername?: string;
  authKey?: string;
  clientId?: string;
  clientSecret?: string;
  clientTokenId?: string;
  data?: any;
  dataroomId?: string;
  dataroomName?: string;
  deviceId?: string;
  dir?: string;
  doCheckout?: string;
  documentClass?: string;
  documentType?: string;
  downloadProhibited?: string;
  fileProgressData?: any;
  finalTargetClientId?: string;
  from?: string;
  item?: any;
  itemId?: string;
  itemIds?: string[];
  itemType?: string;
  iterator?: string;
  jobId?: string;
  messageId?: string;
  method?: string;
  modifiedAttributes?: any;
  mountpoint?: string;
  name?: string;
  newItemAuthor?: string;
  newItemId?: string;
  newItemName?: string;
  newItemText?: string;
  newItemType?: string;
  notificationType?: string;
  requestedAttributes?: any;
  route?: string;
  scope?: string;
  searchTerm?: string;
  serverId?: string;
  serverSecret?: string;
  settings?: any;
  sortDirection?: string;
  sortOrder?: string;
  startIndex?: string;
  subject?: string;
  targetClientId?: string;
  targetClientTokenId?: string;
  targetId?: string;
  title?: string;
  trustedToken?: string;
  uploadFinished?: string;
  usePdf?: string;
  userAgent?: string;
  userId?: string;
  username?: string;
  verifyOnly?: string;
  websocketname?: string;
};

type Service = {
  baseUrl?: string;
  id?: string;
  name?: string;
  node?: {
    name?: string;
    method?: string;
    projectName?: string;
  };
  type?: string;
  version?: string;
};

type User = {
  clientId?: string;
  email?: string;
  id?: string;
  name?: string;
  displayName?: string;
  userVerification?: string;
  withCredentials?: string;
};

type UrlInfo = {
  full?: string;
  original?: string;
  query?: string;
};

type Headers = {
  accept?: string;
  authorization?: string;
  contentLength?: string;
  cookie?: string;
  fingerprint?: string;
  forwardedFor?: string;
  requestId?: string;
};

type Authenticator = {
  requiresResidentKey?: string;
  userVerification?: string;
};

type OptionsInfo = {
  allowCredentials?: string;
  attestation?: string;
  attestationType?: string;
  authenticatorSelection?: Authenticator;
  challenge?: string;
  challengeId?: string;
  expectedChallenge?: string;
  expectedOrigin?: string;
  expectedRPID?: string;
  headers?: Headers;
  timeout?: string;
  ttl?: string;
  user?: User;
};

export type EcsLog = {
  clientIp?: string;
  config?: Record<string, any>;
  details?: string;
  error?: Error;
  function?: string;
  geoData?: GeoData;
  logDate?: string;
  logLevelName?: string;
  logLocation?: string;
  message: string;
  options?: OptionsInfo;
  params?: Params;
  preserve: boolean;
  referer?: string;
  reqparams?: any;
  requestId: string;
  service?: Service;
  source?: { host?: string };
  url?: UrlInfo;
  user?: User;
  user_agent?: { original?: string };
};

let logEntries: EcsLog[] = [];
let mainLogger: MainLogger<unknown> | null = null;
export const gZeroRequestId = '00000000-0000-0000-0000-000000000000';

async function sendToServer(endPoint: string, entries: EcsLog[]) {
  if (endPoint === '' || endPoint === 'console') {
    return;
  }
  let logUrl = 'nf/api/v1/log';
  if (!endPoint.endsWith('/')) {
    logUrl = '/' + logUrl;
  }

  const entriesToSend: EcsLog[] = [];
  for (let i = 0; i < entries.length; i++) {
    const oneEntry = entries[i];
    const err = oneEntry.error;
    if (err !== undefined) {
      const errorContent = err as Record<string, any>;
      const config = errorContent.config;
      if (config !== undefined) {
        if (config.headers.Authorization !== undefined) {
          config.headers.Authorization = 'Basic ****';
        }
        if (config.data.password !== undefined) {
          config.data.password = '****';
        }
        errorContent.config = config;
      }
      const data = errorContent.data;
      if (typeof data === 'string') {
        try {
          errorContent.data = JSON.parse(data);
        } catch (error) {
          errorContent.data = null;
          console.log(error);
        }
      }
      oneEntry.error = errorContent as Error;
    }
    entriesToSend.push(oneEntry);
  }

  try {
    await axios.post(endPoint + logUrl, entriesToSend, {
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
    });
  } catch (error) {
    console.warn(error);
    console.warn(entriesToSend);
  }
}

function fetchFromRawHeaders(
  rawHeaders: string[] | undefined,
  key: string,
): string | undefined {
  if (rawHeaders === undefined) {
    return undefined;
  }
  key = key.toLowerCase();
  for (let i = 0; i < rawHeaders.length; i++) {
    if (rawHeaders[i].toLowerCase() === key) {
      return rawHeaders[i + 1];
    }
  }
  return undefined;
}

function getClientIp(req: Request | undefined): string | undefined {
  if (req === undefined) {
    return undefined;
  }
  let forwardedFor: string | string[] | undefined = req.ip;
  if (forwardedFor !== undefined) {
    return forwardedFor as string | undefined;
  }
  if (req.headers !== undefined) {
    forwardedFor = req.headers['X-Real-Ip'];
    if (forwardedFor !== undefined) {
      return forwardedFor as string | undefined;
    }

    forwardedFor = req.headers['X-Forwarded-For'];
    if (
      forwardedFor === undefined &&
      req.socket !== undefined &&
      req.socket !== null
    ) {
      forwardedFor = req.socket.remoteAddress;
    } else {
      if (Array.isArray(forwardedFor)) {
        forwardedFor = forwardedFor[0];
      }
      if (forwardedFor !== undefined && forwardedFor.includes(',')) {
        forwardedFor = forwardedFor.split(',')[0].trim();
      }
    }
    if (forwardedFor !== undefined) {
      return forwardedFor as string | undefined;
    }
  }

  // I have no idea  why this happens, but it arrives headers
  // without having extracted the client's ip
  // Now I really need to do some brute-force searching - ARGHH!!!
  return fetchFromRawHeaders(req.rawHeaders, 'x-real-ip');
}

function extractParams(oneEntry: LogFormat): Params | undefined {
  const params = oneEntry.params;
  if (params === undefined || params === null) {
    return undefined;
  }
  const retval: Params = {
    action: params.action,
    activity: params.activity,
    activityLogType: params.activityLogType,
    additionalRights: params.AdditionalRights ?? params.additionalRights,
    annotations: params.annotations,
    apiKey: params.apiKey,
    apiServerId: params.apiServerId,
    apiServerSecret: params.apiServerSecret,
    apiUsername: params.api?.username,
    authKey: params.authKey,
    clientId: params.clientId,
    clientSecret: params.clientSecret,
    clientTokenId: params.clientTokenId,
    data: params.data,
    dataroomId: params.dataroomId,
    dataroomName: params.dataroomName,
    deviceId: params.deviceId,
    dir: params.dir,
    doCheckout: params.doCheckout,
    documentClass: params.documentClass,
    documentType: params.documentType,
    downloadProhibited: params.downloadProhibited,
    fileProgressData: params.fileProgressData,
    finalTargetClientId: params.finalTargetClientId,
    from: params.from,
    item: params.item,
    itemId: params.itemId,
    itemIds: params.itemIds,
    itemType: params.itemType,
    iterator: params.iterator,
    jobId: params.jobId,
    messageId: params.messageId,
    method: params.method,
    modifiedAttributes: params.modifiedAttributes,
    mountpoint: params.mountpoint,
    name: params.name,
    newItemAuthor: params.newItemAuthor,
    newItemId: params.newItemId,
    newItemName: params.newItemName,
    newItemText: params.newItemText,
    newItemType: params.newItemType,
    notificationType: params.notificationType,
    requestedAttributes: params.requestedAttributes,
    route: params.route,
    scope: params.scope,
    searchTerm: params.searchTerm,
    serverId: params.serverId,
    serverSecret: params.serverSecret,
    settings: params.settings,
    sortDirection: params.sortDirection,
    sortOrder: params.sortOrder,
    startIndex: params.startIndex,
    subject: params.subject,
    targetClientId: params.targetClientId,
    targetClientTokenId: params.targetClientTokenId,
    targetId: params.targetId,
    title: params.title,
    trustedToken: params.trustedToken,
    uploadFinished: params.uploadFinished,
    usePdf: params.usePdf,
    userAgent: params.userAgent,
    userId: params.userId,
    username: params.username,
    verifyOnly: params.verifyOnly,
    websocketname: params.websocketname,
  };
  return retval;
}

function extractGeoData(oneEntry: LogFormat): GeoData | undefined {
  const params = oneEntry.params;
  if (params === undefined || params === null) {
    return undefined;
  }
  const geoData = params.geoData ?? params.data?.geoData;
  return geoData as GeoData;
}

function extractHeaders(
  headers: Record<string, any> | undefined | null,
): Headers | undefined {
  if (headers === undefined || headers === null) {
    return undefined;
  }
  const retval = {
    accept: headers.Accept,
    authorization: headers.Authorization,
    contentLength: headers['Content-Length'],
    cookie: headers.Cookie,
    fingerprint: headers.fingerprint,
    forwardedFor: headers['X-Forwarded-For'],
    requestId: headers['X-Request-Id'],
  };
  const auth = retval.authorization;
  if (auth === undefined || auth === null) {
    return retval;
  }
  if (auth.toLowerCase().startsWith('Basic')) {
    retval.authorization = 'Basic ****';
  }
  return retval;
}

function extractUserFromOptions(
  options: Record<string, any>,
): User | undefined {
  const userInfo = options.user;
  return {
    name: options.userName ?? userInfo?.name,
    id: options.userID ?? userInfo?.id,
    displayName: userInfo?.displayName,
    userVerification: options.userVerification,
    withCredentials: options.withCredentials,
  };
}

function extractOptions(oneEntry: LogFormat): OptionsInfo | undefined {
  const options = oneEntry.options;
  if (options === undefined || options === null) {
    return undefined;
  }
  return {
    allowCredentials: options.allowCredentials,
    attestation: options.attestation,
    attestationType: options.attestationType,
    authenticatorSelection:
      options.authenticatorSelection === undefined
        ? undefined
        : {
          requiresResidentKey:
              options.authenticatorSelection?.requiresResidentKey,
          userVerification: options.authenticatorSelection?.userVerification,
        },
    challenge: options.challenge,
    challengeId: options.challengeId,
    expectedChallenge: options.expectedChallenge,
    expectedOrigin: options.expectedOrigin,
    expectedRPID: options.expectedRPID,
    headers: extractHeaders(options.headers),
    timeout: options.timeout,
    ttl: options.ttl,
    user: extractUserFromOptions(options),
  };
}

function extractUser(oneEntry: LogFormat): User | undefined {
  const user = oneEntry.user;
  if (user === undefined || user === null) {
    return undefined;
  }
  return {
    name: user.username,
    clientId: user.clientId,
    id: user.userId,
    email: user.email,
  };
}

function convertLog(oneEntry: LogFormat, baseUrl: string): EcsLog {
  if ((oneEntry as EcsLog).logLevelName !== undefined) {
    return oneEntry as EcsLog;
  }
  let requestId = oneEntry.requestId;
  const req = oneEntry.req;
  if (req !== undefined) {
    requestId =
      fetchFromRawHeaders(req.rawHeaders, 'x-request-id') ?? requestId;
  }
  let name = '_UNKNOWN_';
  const meta = oneEntry._meta;
  if (meta !== undefined && meta !== null) {
    const parents = meta.parentNames;
    if (parents !== undefined && parents !== null && parents.length > 0) {
      name = parents[0];
    }
  }
  const logEntry: EcsLog = {
    clientIp: oneEntry.clientIp ?? getClientIp(oneEntry.req) ?? '',
    config: oneEntry.params?.config,
    details: oneEntry.details,
    error: oneEntry.error,
    function: oneEntry.function,
    geoData: extractGeoData(oneEntry),
    logDate: oneEntry._meta?.date,
    logLevelName: oneEntry._meta?.logLevelName ?? 'INFO',
    message:
      oneEntry.msg ?? oneEntry.description ?? oneEntry.details ?? '_UNKNOWN_',
    options: extractOptions(oneEntry),
    params: extractParams(oneEntry),
    preserve:
      oneEntry.preserve ?? (req !== undefined && req.preserveLog) ?? false,
    referer: fetchFromRawHeaders(oneEntry.req?.rawHeaders, 'referer'),
    reqparams: oneEntry.req?.params,
    requestId,
    service: {
      name,
      type: meta?.runtime,
      // Disable lint here as the import does not exist in shared
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      version: appVersion + '@' + gitInfo.hash,
      baseUrl: oneEntry.baseUrl ?? baseUrl,
      id: meta?.hostname,
      node: {
        name: meta?.name,
        method: meta?.path?.method,
        projectName: meta?.projectID,
      },
    },
    logLocation: meta.path?.fullFilePath,
    source: {
      host: meta?.hostname,
    },
    url: {
      original: oneEntry.req?.originalUrl,
      full: oneEntry.apiCallUrl,
      query: oneEntry.req?.query,
    },
    user: extractUser(oneEntry),
    user_agent: {
      original: fetchFromRawHeaders(oneEntry.req?.rawHeaders, 'user-agent'),
    },
  };
  return logEntry;
}

export async function performLogging(
  endPoint: string,
  logObj: LogFormat,
  buffer: number,
  baseUrl: string,
) {
  const outputLog: EcsLog = convertLog(logObj, baseUrl);
  if (endPoint === '' || endPoint === 'console') {
    console.log(outputLog);
    return;
  }
  logEntries.push(outputLog);
  if (logEntries.length >= buffer) {
    const toSend = [...logEntries];
    logEntries = [];
    await sendToServer(endPoint, toSend);
  }
}

export function getMainLogger(
  logServerEndpoint: string,
  name: string,
  minLevel = 4,
  baseUrl = '',
  buffer = 2,
): MainLogger<unknown> {
  if (mainLogger === null) {
    mainLogger = new MainLogger({
      type: 'hidden',
      name: name,
      minLevel: minLevel,
      hideLogPositionForProduction: true,
      maskValuesOfKeysCaseInsensitive: true,
      maskValuesOfKeys: [
        'password',
        'authorization',
        'newpassword',
        'oldpassword',
        'passphrase',
        'pw',
      ],
    });
  }
  mainLogger.attachTransport(async logObj =>
    performLogging(
      logServerEndpoint,
      logObj as unknown as LogFormat,
      buffer,
      baseUrl,
    ),
  );
  return mainLogger;
}
